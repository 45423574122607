const data = {
    name: [
        'breakfast',
        'saladsAndSnacks',
        'soups',
        'burgers',
        'maindishes',
        'desserts',
        'drinks',
        'drinks',
    ],
    id: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ru: {
        Завтраки: [
            {
                id: 28,
                name: 'Яичница – глазунья с добавками на выбор',
                price: 480,
                description: 'лук, бекон, грибы, сыр, томаты, зелень',
            },
            {
                id: 29,
                name: 'Омлет с добавками на выбор',
                price: 480,
                description: 'лук, бекон, грибы, сыр, томаты, зелень',
            },
            {
                id: 30,
                name: 'Яйца бенедикт с беконом и голландским соусом',
                price: 670,
                description: '',
            },
            {
                id: 31,
                name: 'Яйца роял с лососем, шпинатом и голландским соусом',
                price: 690,
                description: '',
            },
            {
                id: 32,
                name: 'Оладьи из цукини с лососем и рикоттой',
                price: 760,
                description: '',
            },
            {
                id: 33,
                name: 'Каша овсяная',
                price: 420,
                description: '',
            },
            {
                id: 34,
                name: 'Тапиока боул',
                price: 590,
                description: '',
            },
            {
                id: 35,
                name: 'Блинчики по-русски',
                price: 460,
                description:
                    'подаются со сметаной и домашним вареньем',
            },
            {
                id: 36,
                name: 'Панкейки с ванильным кремом и клубникой',
                price: 610,
                description: '',
            },
            {
                id: 37,
                name: 'Сырники с сезонными ягодами и сметаной',
                price: 520,
                description: '',
            },
            {
                id: 82,
                name: 'Тост с авокадо, томатами конкассе и жареным яйцом',
                price: 550,
                description: '',
            },
            {
                id: 83,
                name: 'Французский тост с ветчиной и сыром',
                price: 500,
                description: '',
            },

            {
                id: 38,
                name: 'Красная икра с блинами',
                price: 1100,
                description: '',
            },
            {
                id: 8,
                name: 'Икра осетровая с блинами',
                price: 7200,
                description: '50 гр',
            },
        ],
        'Салаты и закуски': [
            {
                id: 1,
                name: 'Сет кростини с авокадо',
                price: 960,
                description: '',
            },
            {
                id: 2,
                name: 'Сет кростини с лососем',
                price: 1050,
                description: '',
            },
            {
                id: 3,
                name: 'Итальянский сет к вину',
                price: 1350,
                description:
                    'Итальянские колбасы и сыры, артишоки гриль, оливки, кростини',
            },
            {
                id: 4,
                name: 'Цезарь с цыпленком',
                price: 960,
                description: '',
            },
            {
                id: 5,
                name: 'Цезарь с креветками',
                price: 1090,
                description: '',
            },
            {
                id: 6,
                name: 'Теплый салат с говядиной',
                price: 860,
                description: '',
            },
            {
                id: 7,
                name: 'Салат из сезонных овощей с халуми',
                price: 790,
                description: '',
            },
        ],
        Супы: [
            {
                id: 9,
                name: ' Борщ с пампушками и сметаной',
                price: 850,
                description: '',
            },
            {
                id: 10,
                name: 'Куриный суп с домашней лапшой',
                price: 660,
                description: '',
            },
        ],
        'Бургеры и сендвичи': [
            {
                id: 11,
                name: 'Кайенский бургер',
                price: 1350,
                description:
                    'Котлета из мраморной говядины, овощи гриль, соус с кайенским перцем. Подается с картофелем фри и домашними соусами',
            },
            {
                id: 12,
                name: 'Domina чизбургер',
                price: 1350,
                description:
                    'Котлета из мраморной говядины, Чеддер, огуречный релиш. Подается с картофелем фри и домашними соусами',
            },
            {
                id: 13,
                name: 'Врап с цыпленком',
                price: 840,
                description: '',
            },
            {
                id: 14,
                name: 'Клубный сэндвич',
                price: 1090,
                description:
                    'Подается с картофелем фри и домашними соусами',
            },
        ],
        'Горячие блюда': [
            {
                id: 15,
                name: 'Филе лосося с черным рисом и соусом биск',
                price: 1950,
                description: '',
            },
            {
                id: 16,
                name: 'Медальоны из говядины с битым картофелем',
                price: 1260,
                description: '',
            },
            {
                id: 17,
                name: 'Соте из креветок и кальмара',
                price: 1440,
                description: '',
            },
            {
                id: 18,
                name: 'Спагетти карбонара',
                price: 880,
                description: '',
            },
            {
                id: 19,
                name: 'Ригатони качо э пепе',
                price: 760,
                description: '',
            },
       
        ],
        Десерты: [
            {
                id: 20,
                name: 'Крем брюле с сезонными ягодами',
                price: 620,
                description: '',
            },
            {
                id: 21,
                name: 'Миндальный дакуаз с клубникой',
                price: 580,
                description: '',
            },
            {
                id: 23,
                name: 'Фруктовая тарелка',
                price: 1300,
                description: '',
            },
            {
                id: 24,
                name: 'Ассорти итальянских сыров',
                price: 1060,
                description: '',
            },
            {
                id: 25,
                name: 'Домашнее мороженое и сорбеты',
                price: 290,
                description: '',
            },
        ],
        'Все свежевыжатые соки объемом 0.2': [
            {
                id: 26,
                name: 'Свежевыжатый апельсиновый сок',
                price: 520,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 27,
                name: 'Свежевыжатый грейпфрутовый сок',
                price: 520,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 39,
                name: 'Свежевыжатый яблочный сок',
                price: 520,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 40,
                name: 'Свежевыжатый морковный сок',
                price: 520,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 41,
                name: 'Свежевыжатый ананасовый сок',
                price: 660,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 42,
                name: 'Свежевыжатый сельдереевый сок',
                price: 660,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 43,
                name: 'Минеральная вода San Benedetto',
                price: 440,
                description: '250 мл',
                flag: 'bev',
            },
            {
                id: 44,
                name: 'Минеральная вода San Benedetto',
                price: 790,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 45,
                name: 'Эспрессо',
                price: 320,
                description: '',
                flag: 'bev',
            },
            {
                id: 46,
                name: 'Американо',
                price: 320,
                description: '',
                flag: 'bev',
            },
            {
                id: 47,
                name: 'Капучино',
                price: 360,
                description: '',
                flag: 'bev',
            },
            {
                id: 48,
                name: 'Латте',
                price: 360,
                description: '',
                flag: 'bev',
            },
            {
                id: 49,
                name: 'Чёрный чай',
                price: 490,
                description: '400 мл',
                flag: 'bev',
            },
            {
                id: 50,
                name: 'Зелёный чай',
                price: 490,
                description: '400 мл',
                flag: 'bev',
            },
            {
                id: 51,
                name: 'Чай фруктовый и травяной',
                price: 490,
                description: '400 мл',
                flag: 'bev',
            },
        ],
        'Алкогольные напитки': [
            {
                id: 52,
                name: 'Бутылочное пиво Primator Premium',
                price: 650,
                description: '500 мл',
                flag: 'bev',
            },
            {
                id: 84,
                name: 'Бутылочное пиво Primator Dark',
                price: 650,
                description: '500 мл',
                flag: 'bev',
            },
            {
                id: 85,
                name: 'Бутылочное пиво Primator Weizenbier',
                price: 650,
                description: '500 мл',
                flag: 'bev',
            },
            {
                id: 53,
                name: 'Бутылочное пиво Kristoffel Blond',
                price: 500,
                description: '330 мл',
                flag: 'bev',
            },
            {
                id: 86,
                name: 'Бутылочное пиво Kristoffel Brune',
                price: 500,
                description: '330 мл',
                flag: 'bev',
            },  {
                id: 87,
                name: 'Бутылочное пиво Kristoffel Witte',
                price: 500,
                description: '330 мл',
                flag: 'bev',
            },
            {
                id: 54,
                name: 'Яблочный сидр Royal Demi-Sec Cider',
                price: 420,
                description: '330 мл',
                flag: 'bev',
            },
            {
                id: 82,
                name: 'Безалкогольное пиво Arcobrau',
                price: 550,
                description: '500 мл',
                flag: 'bev',
            },
            {
                id: 55,
                name: 'Вермут Martini Bianco',
                price: 350,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 56,
                name: 'Биттер Jagermeister',
                price: 640,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 57,
                name: 'Водка Онегин',
                price: 400,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 58,
                name: 'Водка Beluga',
                price: 450,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 59,
                name: 'Джин Beefeater',
                price: 590,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 60,
                name: 'Ром Havana Club Anejo 3 y.o.',
                price: 640,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 61,
                name: 'Виски The Macallan 12 y.o.',
                price: 2200,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 62,
                name: 'Виски Chivas Regal 12 y.o.',
                price: 1050,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 63,
                name: 'Виски Jameson',
                price: 750,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 64,
                name: 'Виски Jack Daniel’s',
                price: 750,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 65,
                name: 'Коньяк Hine Rare VSOP',
                price: 1600,
                description: '50 мл',
                flag: 'bev',
            },
            {
                id: 66,
                name: 'Вино игристое PROSECCO Cielo Brut, Veneto, Italy',
                price: 3900,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 67,
                name: 'Вино игристое CRÉMANT DE LOIRE Arnaud Lambert, France',
                price: 7800,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 68,
                name: 'Шампанское PIERRE GIMONNET Cuis Premier Cru, France',
                price: 16500,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 69,
                name: 'Шампанское LANSON Rose Label Brut, France',
                price: 18900,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 70,
                name: 'Шампанское DRAPPIER Carte d Or Brut, France',
                price: 15000,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 71,
                name: 'Белое вино GRUNER VELTLINER Nastl Kittmannsberg, Austria',
                price: 5800,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 72,
                name: 'Белое вино SAUVIGNON BLANC Marlborough Rapaura Reserve, New Zealand',
                price: 5900,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 73,
                name: 'Белое вино PINOT GRIGIO Elena Walch, Italy',
                price: 6700,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 74,
                name: 'Белое вино CHABLIS Jean-Marc Brocard Sainte-Claire, Burgundy, France',
                price: 8200,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 75,
                name: 'Белое вино CHARDONNAY Bourgogne Laforet Joseph Drouhin, Burgundy, France',
                price: 9900,
                description: '750 мл',
                flag: 'bev',
            },
                    {
                id: 76,
                name: 'Вино красное MALBEC La Posta Domingo Fazzio, Argentina',
                price: 5900,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 77,
                name: 'Вино красное PINOT NOIR Oleg Repin Sevastopol, Russia',
                price: 6800,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 78,
                name: 'Вино красное CHIANTI CLASSICO Riserva San Felice Il Grigio, Toscana',
                price: 8100,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 79,
                name: 'Вино красное SEXY BEAST Two Hands, McLaren Vale, Australia',
                price: 9300,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 80,
                name: 'Вино красное HAUT-MEDOC Les Allees de Cantemerle, Bordeaux, France',
                price: 10500,
                description: '750 мл',
                flag: 'bev',
            },
            {
                id: 81,
                name: 'Вино розовое COTES DE PROVENCE Emma Dames du Sud, France',
                price: 5500,
                description: '750 мл',
                flag: 'bev',
            },
        ],
    },
    en: {
        Breakfast: [
            {
                id: 28,
                name: 'Fried eggs with toppings of your choice',
                price: 480,
                description:
                    'onion, bacon, mushrooms, cheese, tomatoes, greens',
            },
            {
                id: 29,
                name: 'Omelet with toppings of your choice',
                price: 480,
                description:
                    'onion, bacon, mushrooms, cheese, tomatoes, greens',
            },
            {
                id: 30,
                name: 'Eggs Benedict with bacon and Hollandaise sauce',
                price: 670,
                description: '',
            },
            {
                id: 31,
                name: 'Royal eggs with salmon, spinach and Hollandaise sauce',
                price: 690,
                description: '',
            },
            {
                id: 32,
                name: 'Zucchini pancakes with salmon and ricotta',
                price: 760,
                description: '',
            },
            {
                id: 33,
                name: 'Oatmeal porridge',
                price: 420,
                description: '',
            },
            {
                id: 34,
                name: 'Tapioca bowl',
                price: 590,
                description: '',
            },
            {
                id: 35,
                name: 'Russian style pancakes',
                price: 460,
                description: 'served with jam and sour cream',
            },
            {
                id: 36,
                name: 'Pancakes with vanilla cream and strawberries',
                price: 610,
                description: '',
            },
            {
                id: 37,
                name: 'Cottage cheese pancakes with seasonal berries and sour cream',
                price: 520,
                description: '',
            },
            {
                id: 82,
                name: 'Avocado toast, concasse tomatoes and fried egg',
                price: 550,
                description: '',
            },
            {
                id: 83,
                name: 'French toast with ham and cheese',
                price: 500,
                description: '',
            },

            {
                id: 38,
                name: 'Salmon caviar with with russian pancakes',
                price: 1100,
                description: '',
            },
            {
                id: 8,
                name: 'Black caviar with russian pancakes',
                price: 7200,
                description: '50 gr',
            },
        ],
        'Salads and Snacks': [
            {
                id: 1,
                name: 'Set of crostini with avocado',
                price: 960,
                description: '',
            },
            {
                id: 2,
                name: 'Set of crostini with salmon',
                price: 1050,
                description: '',
            },
            {
                id: 3,
                name: 'Italian wine set',
                price: 1350,
                description:
                    'italian sausages and cheese, grilled artichokes, olives, crostini',
            },
            {
                id: 4,
                name: 'Caesar salad with chicken',
                price: 960,
                description: '',
            },
            {
                id: 5,
                name: 'Caesar salad with tiger shrimps',
                price: 1090,
                description: '',
            },
            {
                id: 6,
                name: 'Warm salad with beef',
                price: 860,
                description: '',
            },
            {
                id: 7,
                name: 'Seasonal vegetables with halloumi',
                price: 790,
                description: '',
            },
        ],
        Soups: [
            {
                id: 9,
                name: 'Borsch with garlic bread rolls and sour cream',
                price: 850,
                description: '',
            },
            {
                id: 10,
                name: 'Chicken broth with noodles',
                price: 660,
                description: '',
            },
        ],
        'Burgers and Sandwiches': [
            {
                id: 11,
                name: 'Cayenne burger ',
                price: 1350,
                description:
                    'Marbled beef patty, grilled vegetables, sauce with cayenne pepper Served with French fries and homemade sauces',
            },
            {
                id: 12,
                name: 'Domina cheeseburger',
                price: 1350,
                description:
                    'Marbled beef patty, Cheddar, cucmber relish. Served with French fries and homemade sauces',
            },
            {
                id: 13,
                name: 'Chicken wrap',
                price: 840,
                description: '',
            },
            {
                id: 14,
                name: 'Club sandwich',
                price: 1090,
                description:
                    'Served with French fries and homemade sauces',
            },
        ],
        'Main Dishes': [
            {
                id: 15,
                name: 'Salmon fuket with black rice and bisque sace',
                price: 1950,
                description: '',
            },
            {
                id: 16,
                name: 'Beef medalions with smashed potatoes',
                price: 1260,
                description: '',
            },
            {
                id: 17,
                name: 'Sauteed king prawns and squid',
                price: 1440,
                description: '',
            },
            {
                id: 18,
                name: 'Spaghetti alla carbonara',
                price: 880,
                description: '',
            },
            {
                id: 19,
                name: 'Rigatoni cacio e pepe',
                price: 760,
                description: '',
            },
            
        ],
        Desserts: [
            {
                id: 20,
                name: 'Cream brulee',
                price: 620,
                description: '',
            },
            {
                id: 21,
                name: 'Almond dacquoise with strawberry',
                price: 580,
                description: '',
            },
            {
                id: 23,
                name: 'Fruit plate',
                price: 1300,
                description: '',
            },
            {
                id: 24,
                name: 'Italian cheese plate',
                price: 1060,
                description: '',
            },
            {
                id: 25,
                name: 'Homemade ice-cream and sorbet',
                price: 290,
                description: '',
            },
        ],
        'Freshly Squeezed Juices': [
            {
                id: 26,
                name: 'Freshly squeezed orange juice',
                price: 520,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 27,
                name: 'Freshly squeezed grapefruit juice',
                price: 520,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 39,
                name: 'Freshly squeezed apple juice',
                price: 520,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 40,
                name: 'Freshly squeezed carrot juice',
                price: 520,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 41,
                name: 'Freshly squeezed pineapple juice',
                price: 520,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 42,
                name: 'Freshly squeezed celery juice',
                price: 660,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 43,
                name: 'Mineral Water San Benedetto',
                price: 440,
                description: '250 ml',
                flag: 'bev',
            },
            {
                id: 44,
                name: 'Mineral Water San Benedetto',
                price: 790,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 45,
                name: 'Espresso',
                price: 320,
                description: '',
                flag: 'bev',
            },
            {
                id: 46,
                name: 'Americano',
                price: 320,
                description: '',
                flag: 'bev',
            },
            {
                id: 47,
                name: 'Cappuccino',
                price: 360,
                description: '',
                flag: 'bev',
            },
            {
                id: 48,
                name: 'Latte',
                price: 360,
                description: '',
                flag: 'bev',
            },
            {
                id: 49,
                name: 'Black Tea',
                price: 490,
                description: '400 ml',
                flag: 'bev',
            },
            {
                id: 50,
                name: 'Green Tea',
                price: 490,
                description: '400 ml',
                flag: 'bev',
            },
            {
                id: 51,
                name: 'Fruit and herbal Tea',
                price: 490,
                description: '400 ml',
                flag: 'bev',
            },
        ],
        'Alcoholic Beverages': [
            {
                id: 52,
                name: 'Bottled beer Primator Premium',
                price: 650,
                description: '500 ml',
                flag: 'bev',
            },
            {
                id: 84,
                name: 'Bottled beer Primator Dark',
                price: 650,
                description: '500 ml',
                flag: 'bev',
            },
            {
                id: 85,
                name: 'Bottled beer Primator Weizenbier',
                price: 650,
                description: '500 ml',
                flag: 'bev',
            },
            {
                id: 53,
                name: 'Bottled beer Kristoffel Blond',
                price: 500,
                description: '330 ml',
                flag: 'bev',
            },
            {
                id: 86,
                name: 'Bottled beer Kristoffel Brune',
                price: 500,
                description: '330 ml',
                flag: 'bev',
            },  {
                id: 87,
                name: 'Bottled beer Kristoffel Witte',
                price: 500,
                description: '330 ml',
                flag: 'bev',
            },
            {
                id: 54,
                name: 'Royal Demi-Sec Apple Cider',
                price: 420,
                description: '330 ml',
                flag: 'bev',
            },
            {
                id: 82,
                name: 'Non-alcohol beer Arcobrau',
                price: 550,
                description: '500 ml',
                flag: 'bev',
            },
            {
                id: 55,
                name: 'Vermouth Martini Bianco',
                price: 350,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 56,
                name: 'Bitter Jagermeister',
                price: 640,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 57,
                name: 'Vodka Onegin',
                price: 400,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 58,
                name: 'Vodka Beluga',
                price: 450,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 59,
                name: 'Gin Beefeater',
                price: 590,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 60,
                name: 'Rum Havana Club Anejo 3 y.o.',
                price: 640,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 61,
                name: 'Whisky The Macallan 12 y.o.',
                price: 2200,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 62,
                name: 'Whisky Chivas Regal 12 y.o.',
                price: 1050,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 63,
                name: 'Whisky Jameson',
                price: 750,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 64,
                name: 'Whisky Jack Daniel’s',
                price: 750,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 65,
                name: 'Cognac Hine Rare VSOP',
                price: 1600,
                description: '50 ml',
                flag: 'bev',
            },
            {
                id: 66,
                name: 'Sparkling wine PROSECCO Cielo Brut, Veneto, Italy',
                price: 3900,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 67,
                name: 'Sparkling wine CRÉMANT DE LOIRE Arnaud Lambert, France',
                price: 7800,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 68,
                name: 'Champagne PIERRE GIMONNET Cuis Premier Cru, France',
                price: 16500,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 69,
                name: 'Champagne LANSON Rose Label Brut, France',
                price: 18900,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 70,
                name: 'Champagne DRAPPIER Carte d Or Brut, France',
                price: 15000,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 71,
                name: 'White wine GRUNER VELTLINER Nastl Kittmannsberg, Austria',
                price: 5800,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 72,
                name: 'White wine SAUVIGNON BLANC Marlborough Rapaura Reserve, New Zealand',
                price: 5900,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 73,
                name: 'White wine PINOT GRIGIO Elena Walch, Italy',
                price: 6700,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 74,
                name: 'White wine CHABLIS Jean-Marc Brocard Sainte-Claire, Burgundy, France',
                price: 8200,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 75,
                name: 'White wine CHARDONNAY Bourgogne Laforet Joseph Drouhin, Burgundy, France',
                price: 9900,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 76,
                name: 'Red wine MALBEC La Posta Domingo Fazzio, Argentina',
                price: 5900,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 77,
                name: 'Red wine PINOT NOIR Oleg Repin Sevastopol, Russia',
                price: 6800,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 78,
                name: 'Red wine CHIANTI CLASSICO Riserva San Felice Il Grigio, Toscana',
                price: 8100,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 79,
                name: 'Red wine SEXY BEAST Two Hands, McLaren Vale, Australia',
                price: 9300,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 80,
                name: 'Red wine HAUT-MEDOC Les Allees de Cantemerle, Bordeaux, France',
                price: 10500,
                description: '750 ml',
                flag: 'bev',
            },
            {
                id: 81,
                name: 'Rose wine COTES DE PROVENCE Emma Dames du Sud, France',
                price: 5500,
                description: '750 ml',
                flag: 'bev',
            },
        ],
    },
};

export default data;
